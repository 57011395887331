import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/plain.css'
import './components/styles/style.css';
import OperatorSignUp from './components/OperatorSignUp';
import PassengerSignUp from './components/PassengerSignUp';

function App() {
  return (
    <div className="App">
      {/* 
        Hero Section
      */}
      <section className="section hero__section">
        <div className="wrapper">
          <div className="wrapper__inner">
            <div className="container">
              <header>
                <div className="logo">
                  <img src="logo.png" alt="Tap & Hop Logo" />
                </div>
              </header>
              <div className="grid grid:1:2">
                <div className="column section__text">
                  <h1>
                    Digitising Fares for Trotro, Taxi and Bus Operators
                    (Owners) in Ghana.
                  </h1>
                  <p>
                    Providing Transit Operators visibility into revenue your
                    vehicles generate
                  </p>
                  <div className="video_button_wrapper">
                    <div className="video_button_inner_wrapper">
                      <a
                        href="https://youtube.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="icon_wrapper icon_circle">
                          <i className="icon icon-play"></i>
                        </div>
                        <div className="icon_wrapper_animation"></div>
                        <span>Watch video overview</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="column section__image">
                  <img
                    src="hero_image.svg"
                    alt="A passenger requestion a taxi"
                  />
                </div>
              </div>
            </div>
            <div className="waves hero__waves">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path d="M0,192L34.3,202.7C68.6,213,137,235,206,229.3C274.3,224,343,192,411,192C480,192,549,224,617,224C685.7,224,754,192,823,192C891.4,192,960,224,1029,234.7C1097.1,245,1166,235,1234,224C1302.9,213,1371,203,1406,197.3L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
      {/* 
        End of Hero Section
      */}
      {/* 
        Why Tap & Hop Section
      */}
      <section className="section why__section">
        <div className="wrapper">
          <div className="wrapper__inner">
            <div className="container">
              <div className="section__header text-center">
                <h1 className="section__header__title">Why Tap&Hop?</h1>
                <p className="section__header__description">
                  Tap & Hop helps transit Operators (Owners) by implementing an
                  end to end AFC (Automated Fare Collection) solution used to
                  digitise the payment of passenger fares for trips giving you
                  visibility into revenues generated.
                </p>
              </div>
              <div className="grid grid:1:3">
                <div className="column section__text text-center">
                  <div className="icon_wrapper icon_circle">
                    <i className="icon icon-credit-card"></i>
                  </div>
                  <h3>Digitise Fare Collections</h3>
                  <p>
                    Tap&Hop helps digitise the collection of Passenger fares
                    using an NFC card, a contactless payment card which they'll
                    carry.
                  </p>
                </div>
                <div className="column section__text text-center">
                  <div className="icon_wrapper icon_circle">
                    <i className="icon icon-bar-chart"></i>
                  </div>
                  <h3>Operator: Revenue</h3>
                  <p>
                    With a digitalised payment system, you have complete
                    transparency into revenue generated. See how much fare your
                    drivers have made right on the Tap&Hop app.
                  </p>
                </div>
                <div className="column section__text text-center">
                  <div className="icon_wrapper icon_circle">
                    <i className="icon icon-money"></i>
                  </div>
                  <h3>Passenger: Fair & Consistent Fares</h3>
                  <p>
                    Tap&Hop implements distance-based fare, meaning that you pay
                    only for the distance you've travelled, eliminating
                    unregulated fare increases.
                  </p>
                </div>
              </div>
            </div>
            <div className="waves why__waves">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path d="M0,192L34.3,202.7C68.6,213,137,235,206,229.3C274.3,224,343,192,411,192C480,192,549,224,617,224C685.7,224,754,192,823,192C891.4,192,960,224,1029,234.7C1097.1,245,1166,235,1234,224C1302.9,213,1371,203,1406,197.3L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
      {/* 
        End of Why Tap & Hop Section
      */}
      {/* 
        Operators Section
      */}
      <section className="section operator__section">
        <div className="wrapper">
          <div className="wrapper__inner">
            <div className="container">
              <div className="grid grid:1:2">
                <div className="column section__text">
                  <h2>What Operators Should Expect from using Tap&Hop</h2>
                  <ul>
                    <li>
                      <div className="icon_wrapper icon_square">
                        <i className="icon icon-credit-card"></i>
                      </div>
                      <p>
                        Digitising fare collections across your vehicle(s),
                        thereby blocking revenue leakages.
                      </p>
                    </li>
                    <li>
                      <div className="icon_wrapper icon_square">
                        <i className="icon icon-bar-chart"></i>
                      </div>
                      <p>
                        You gain transparency into revenue generated by your
                        vehicle(s). You are not reliant on your drivers to
                        account for the day and or week's work.
                      </p>
                    </li>
                    <li>
                      <div className="icon_wrapper icon_square">
                        <i className="icon icon-map"></i>
                      </div>
                      <p>
                        Track your vehicles anywhere at any time using our
                        Operator App or via our Online Portal.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="column section__image">
                  <img
                    src="operator_image.svg"
                    alt="Operator tracking his vehicle"
                  />
                </div>
              </div>
            </div>
            <div className="waves operator__waves">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path d="M0,192L34.3,202.7C68.6,213,137,235,206,229.3C274.3,224,343,192,411,192C480,192,549,224,617,224C685.7,224,754,192,823,192C891.4,192,960,224,1029,234.7C1097.1,245,1166,235,1234,224C1302.9,213,1371,203,1406,197.3L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
      {/* 
        End of Operators Section
      */}
      {/* 
        Newsletter Section
      */}
      <section className="section newsletter__section">
        <div className="wrapper">
          <div className="wrapper__inner">
            <div className="container">
              <div className="grid grid:1:2">
                <div className="column section__text text-center">
                  <h2>Operators (owner) Signup</h2>
                  <OperatorSignUp />
                </div>
                <div className="column section__text text-center">
                  <h2>Passenger Signup</h2>
                  <PassengerSignUp />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
        End of Newsletter Section
      */}
      {/* 
        Passenger Section
      */}
      <section className="section passenger__section">
        <div className="wrapper">
          <div className="wrapper__inner">
            <div className="container">
              <div className="grid grid:1:2">
                <div className="column section__image">
                  <img
                    src="passenger_image.svg"
                    alt="Operator tracking his vehicle"
                  />
                </div>
                <div className="column section__text">
                  <h2>What Passengers Should Expect from using Tap&Hop</h2>
                  <ul>
                    <li>
                      <div className="icon_wrapper icon_square">
                        <i className="icon icon-money"></i>
                      </div>
                      <p>
                        A uniformed distance-based fare charges for Taxis,
                        Trotros and Buses, which means that you only pay for
                        distance travelled, and most importantly, fare pricing
                        is consistent across the country.
                      </p>
                    </li>
                    <li>
                      <div className="icon_wrapper icon_square">
                        <i className="icon icon-credit-card"></i>
                      </div>
                      <p>
                        Use Tap&Hop card, go cashless, and pay for your fares
                        with a single tap on and tap off. You can Top-up your
                        Tap&Hop card anytime, anywhere using your smartphone or
                        any Top-up vendor near you.
                      </p>
                    </li>
                    <li>
                      <div className="icon_wrapper icon_square">
                        <i className="icon icon-smile-o"></i>
                      </div>
                      <p>
                        You don't have to argue with drivers/mates about fares
                        and or having the correct change. Go cashless!
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
        End of Passenger Section
      */}
      <footer className="footer">
        <div className="container">
          <div className="grid grid:1:2">
            <div className="copyright">
              <p>
                &copy;{new Date().getFullYear()} - Tap & Hop | All Rights
                Reserved
              </p>
            </div>
            <div className="socials">
              <ul className="text-right" aria-label="social links">
                <li>
                  <a
                    href="https://facebook.com/tapnhop"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="icon icon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twiiter.com/tapnhop"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Twitter"
                  >
                    <i className="icon icon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/tapnhop"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <i className="icon icon-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
