import { useState } from 'react';

export default function useForm(initial = {}) {
  const [inputs, setInputs] = useState(initial);

  function handleChange(e) {
    let { value, name, type } = e.target;
    
    if(type === 'number') {
      value = parseInt(value)
    }
    if( type === 'file' ) {
      value[0] = e.target.files;
    }
    if(type === 'tel')
    {
      var regExPhone = new RegExp(/^[+]*[0-9]*$/);
      if (!regExPhone.test(value)) {
        return;
      }
    }

    setInputs({
      ...inputs,
      [name]: value
    });
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key, value]) => [key, ''])
    );
    
    setInputs(blankState);
  }

  return {
    inputs,
    handleChange,
    resetForm,
    clearForm
  };
}