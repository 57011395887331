import { useState } from "react";
import useForm from "../lib/useForm";
import ModalPopup from "./ModalPopup";
import PhoneInput from 'react-phone-input-2'


const PassengerSignUp = function() {
  const [modalShow, setModalShow] = useState(false);
  const [passenger_phone, setValue] = useState();
  const { inputs, handleChange, clearForm } = useForm({
    passenger_name: "",
    passenger_phone: "",
    passenger_email: "",
  });
  

  const [feedback, setFeedback] = useState("");
  const [buttonText, setButtonText] = useState("Sign Up");
  const [feedbackStatus, setFeedbackStatus] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  


  async function handleSubmit(e) {
    e.preventDefault();
    inputs.passenger_phone = "+" + passenger_phone;
    setButtonText("Submitting...");
    setFeedbackStatus("");
    setFeedback("");
    setDisableButton(true);
    
    if (!inputs.passenger_name || !inputs.passenger_email) {
      setFeedbackStatus("feedback_error");
      setFeedback("Missing some required fields");
      setButtonText("Sign Up");
      setDisableButton(false);

      return;
    }
    var regExPhone = new RegExp(/^[+]*[0-9]*$/);
    if (!regExPhone.test(inputs.passenger_phone)) {
      setFeedbackStatus("feedback_error");
      setFeedback("Phone must be a numeric value");
      setButtonText("Sign Up");
      setDisableButton(false);

      return;
    }

    let data = {
      name: inputs.passenger_name,
      email: inputs.passenger_email,
      phone: inputs.passenger_phone,
      tag: "passenger",
    };

    fetch(process.env.REACT_APP_SIGNUP_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          clearForm({});
          setValue("233"); //Clear Passenger Phone
          setFeedbackStatus("feedback_success");
          //setShow(true);
          setModalShow(true);
          
          //ModalPopup.handleShow();
          // setFeedback(
          //   "Thank you for submitting your message. We'll get back to you shortly."
          // );
          setButtonText("Sign Up");
          setDisableButton(false);

          return;
        }

        setFeedbackStatus("feedback_error");
        setFeedback(
          "An error occurred while trying to submit your message. Please try again."
        );
        setButtonText("Sign Up");
        setDisableButton(false);

        return;
      });
  }

  return (
    <form id="operator-form" action="" method="post" onSubmit={handleSubmit}>
      
      <div className="input-wrapper">
        <ModalPopup modalShow={modalShow} setModalShow={setModalShow} titleText="Passenger sign up confirmation" bodyText ="Thank you for signing up. We'll get back to you shortly." buttonText = "Great!" />

      
        <label htmlFor="email" className="sr-only">
          Full Name
        </label>
        <input
          type="text"
          id="name"
          name="passenger_name"
          className="input-field"
          placeholder="Full Name (required)"
          value={inputs.passenger_name}
          onChange={handleChange}
        />
      </div>
      <div className="input-wrapper">
        <label htmlFor="phone" className="sr-only">
          Phone
        </label>
      <label className="label-text" htmlFor="phone">Mobile</label>
      <div className="white-background">
      <PhoneInput className="input-field"
        country={'gh'}
        inputProps={{
          name: 'phone',          
          id: 'phone',
        }}
        placeholder = "Mobile"
        value={passenger_phone}
        onChange={setValue}
        label="Fsdfds"
        countryCodeEditable={false}
        
      />   
      </div>     
      </div>
      
      <div className="input-wrapper">
        <label htmlFor="email" className="sr-only">
          Email (required)
        </label>
        <input
          type="email"
          id="email"
          name="passenger_email"
          className="input-field"
          placeholder="Email (required)"
          value={inputs.passenger_email}
          onChange={handleChange}
        />
      </div>
      <div className="button-wrapper text-right">
        <button
          type="submit"
          className="btn btn-inline btn-primary"
          disabled={disableButton}
        >
          {buttonText}
        </button>
      </div>
      {feedback ? (
        <div className={"feedback_wrapper " + feedbackStatus}>
          <p>{feedback}</p>
        </div>
      ) : (
        ""
      )}
    </form>
  );
}

export default PassengerSignUp;
