import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
//function ModalPopup(props){
const ModalPopup  = props => {

    
    return(
        

        <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}      
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.bodyText}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => props.setModalShow(false)}>
            {props.buttonText}
          </Button>
          
        </Modal.Footer>
      </Modal>
      
    );
}
    
export default ModalPopup;
