import { useState } from "react";
import useForm from "../lib/useForm";
import ModalPopup from "./ModalPopup";
import PhoneInput from 'react-phone-input-2'

const OperatorSignUp = function() {
  const [modalShow, setModalShow] = useState(false);
  const [passenger_phone, setValue] = useState();
  const { inputs, handleChange, clearForm } = useForm({
    operator_name: "",
    operator_phone: "",
    operator_email: "",
  });

  const [feedback, setFeedback] = useState("");
  const [buttonText, setButtonText] = useState("Sign Up");
  const [feedbackStatus, setFeedbackStatus] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    inputs.passenger_phone = "+" + passenger_phone;
    setButtonText("Submitting...");
    setFeedbackStatus("");
    setFeedback("");
    setDisableButton(true);

    if (!inputs.operator_name || !inputs.operator_email) {
      setFeedbackStatus("feedback_error");
      setFeedback("Missing some required fields");
      setButtonText("Sign Up");
      setDisableButton(false);

      return;
    }
    var regExPhone = new RegExp(/^[+]*[0-9]*$/);
    if (!regExPhone.test(inputs.operator_phone)) {    
      setFeedbackStatus("feedback_error");
      setFeedback("Phone must be a numeric value");
      setButtonText("Sign Up");
      setDisableButton(false);

      return;
    }

    let data = {
      name: inputs.operator_name,
      email: inputs.operator_email,
      phone: inputs.operator_phone,
      tag: 'operator'
    };

    fetch(process.env.REACT_APP_SIGNUP_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          clearForm({});
          setValue("233"); //Clear Passenger Phone
          
          setModalShow(true);
          
          setButtonText("Sign Up");
          setDisableButton(false);

          return;
        }

        setFeedbackStatus("feedback_error");
        const convertToJSON = JSON.parse(data.result.response.text);
        setFeedback(
          convertToJSON.detail ||
            "An error occurred while trying to submit your message. Please try again."
        );
        setButtonText("Sign Up");
        setDisableButton(false);

        return;
      })
      .catch((err) => {
        setFeedbackStatus("feedback_error");
        setFeedback("Something went wrong. - " + err.message);
        setButtonText("Sign Up");
        setDisableButton(false);

        return;
      });
  }

  return (
    <form id="operator-form" action="" method="post" onSubmit={handleSubmit}>
      
      <div className="input-wrapper">
        <ModalPopup modalShow={modalShow} setModalShow={setModalShow} titleText="Operator sign up confirmation" bodyText ="Thank you for signing up. We'll be in touch shortly." buttonText = "Great!" />
        <label htmlFor="email" className="sr-only">
          Full Name
        </label>
        <input
          type="text"
          id="name"
          name="operator_name"
          className="input-field"
          placeholder="Full Name (required)"
          value={inputs.operator_name}
          onChange={handleChange}
        />
      </div>
      <div className="input-wrapper">
        <label htmlFor="phone" className="sr-only">
          Phone
        </label>
      <label className="label-text" htmlFor="phone">Mobile </label>
      <div className="white-background">
      <PhoneInput className="input-field"
        country={'gh'}
        inputProps={{
          name: 'phone',          
          id: 'phone',
        }}
        name="phone"        
        placeholder = "Mobile"
        value={passenger_phone}
        onChange={setValue}
        label="Fsdfds"
        countryCodeEditable={false}
        
      />   
      </div>     
      </div>
      <div className="input-wrapper">
        <label htmlFor="email" className="sr-only">
          Email (required)
        </label>
        <input
          type="email"
          id="email"
          name="operator_email"
          className="input-field"
          placeholder="Email (required)"
          value={inputs.operator_email}
          onChange={handleChange}
        />
      </div>
      <div className="button-wrapper text-right">
        <button
          type="submit"
          className="btn btn-inline btn-primary"
          disabled={disableButton}
        >
          {buttonText}
        </button>
      </div>
      {feedback ? (
        <div className={"feedback_wrapper " + feedbackStatus}>
          <p>{feedback}</p>
        </div>
      ) : (
        ""
      )}
    </form>
  );
}

export default OperatorSignUp;
